import AppClient from '../AppClient';

import { QuestionsList, QuestionsListResult } from './ListInterface';
import {
  QuestionList, SaveList, ListAnswers, ListAnswersTRI 
} from '@/globalInterfaces/List';

export default class ListService {
  private basePath = process.env.VUE_APP_SIMULATION_API;

  private Client = new AppClient({ url: this.basePath });

  async getListQuestions(data?: QuestionsList): Promise<QuestionList[]> {
    const URI = '/lists/questions';

    const response = await this.Client.get(URI, data);

    return response?.data;
  }

  async saveList(data?: SaveList): Promise<any> {
    const URI = '/lists/answer';

    const response = await this.Client.post(URI, data);

    return response?.data;
  }

  async getListQuestionsResult(data?: QuestionsListResult): Promise<ListAnswers[]> {
    const URI = '/lists/answer';

    const response = await this.Client.get(URI, data);

    return response?.data;
  }

  async getListQuestionsResultTRI(listID: number): Promise<ListAnswersTRI> {
    const URI = `/lists/${listID}/answer/tri`;

    const response = await this.Client.get(URI);

    return response?.data;
  }
}
