







































import { Component, Vue } from 'vue-property-decorator';
import Recruit from '@/assets/icons/war/training/Recruit.vue';
import Call from '@/assets/icons/war/training/Call.vue';
import Selection from '@/assets/icons/war/training/Selection.vue';
import Incorporation from '@/assets/icons/war/training/Incorporation.vue';
import Improvement from '@/assets/icons/war/training/Improvement.vue';
import Info from '@/assets/icons/war/Info.vue';
import Modal from './components/Modal/Modal.vue';
import ModalInfo from './components/ModalInfo/ModalInfo.vue';

const STEPS = [
  {
    key: 'recruit',
    stepKey: 'recruit',
    title: 'Recrutar',
    color: '#3B5BDB',
    icon: Recruit
  },
  {
    key: 'call',
    stepKey: 'call',
    title: 'Convocar',
    color: '#760E1A',
    icon: Call
  },
  {
    key: 'selection',
    stepKey: 'selection',
    title: 'Seleção',
    color: '#F2A900',
    icon: Selection
  },
  {
    key: 'incorporation',
    title: 'Incorporação',
    stepKey: 'incorporation',
    color: '#267E2C',
    icon: Incorporation
  },
  {
    key: 'improvement',
    stepKey: 'improvement',
    title: 'Aprimoramento',
    color: '#A505F0',
    icon: Improvement
  }
];

@Component({
  components: {
    Recruit,
    Call,
    Selection,
    Incorporation,
    Improvement,
    Modal,
    ModalInfo,
    Info
  }
})
export default class TrainingSteps extends Vue {
  private steps = STEPS;
  private dotSize = 3;
  private dotSpacing = 5;
  private activeStep = 0;
  private isModalOpen = false;

  setActiveStep(index: number): void {
    this.activeStep = index;
    this.$emit('step-changed', index);
  }

  handleModal(): void {
    this.isModalOpen = !this.isModalOpen;
  }
}
