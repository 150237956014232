


























import { Component, Prop, Vue } from 'vue-property-decorator';
import Sword from '@/assets/icons/war/Sword.vue';
import ArrowTarget from '@/assets/icons/war/ArrowTarget.vue';
import TankDetailed from '@/assets/icons/war/TankDetailed.vue';
import Aeroplane from '@/assets/icons/war/Aeroplane.vue';
import Cap from '@/assets/icons/war/Cap.vue';
import Plus from '@/assets/icons/war/Plus.vue';

@Component({
  components: {
    Sword,
    ArrowTarget,
    TankDetailed,
    Aeroplane,
    Cap,
    Plus
  }
})
export default class SidebarCard extends Vue {
  @Prop({ required: true, type: String }) iconName!: string;
  @Prop({ required: true, type: String }) label!: string;
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: String }) recurrence!: 'high' | 'medium' | 'low';

  get iconComponent() {
    const iconMap: { [key: string]: any } = {
      sword: Sword,
      target: ArrowTarget,
      tank: TankDetailed,
      aeroplane: Aeroplane
    };

    return iconMap[this.iconName?.toLowerCase()] || null;
  }
}
