









































import {
  Component,
  Watch,
  Mixins,
  Prop
} from 'vue-property-decorator';
import { format, differenceInSeconds } from 'date-fns';

import Tabs, { TabsMenuInterface } from '@/components/Tabs/Tabs.vue';
import Loading from '@/components/Loading/Loading.vue';
import ContainerInstructionProposal from '@/components/Redaction/ContainerInstructionProposal/ContainerInstructionProposal.vue';
import ContainerSendRedaction from '@/components/Redaction/ContainerSendRedaction/ContainerSendRedaction.vue';
import ButtonMovie from '@/components/ButtonMovie/ButtonMovie.vue';
import QuestionsListContainer from '@/components/QuestionsListContainer/QuestionsListContainer.vue';
import FeedbackUser from '@/components/FeedbackUser/index.vue';
import QuestionLoading from '@/components/Exercise/Question/QuestionLoading.vue';

import Buttons from './components/Buttons/Buttons.vue';

import ActiveModal from '@/share/Util/ActiveModal';
import { UrlTreatmentSaveVersa } from '@/share/Util/Image/Image';
import ScrollControl from '@/share/Util/ScrollControl/ScrollControl';

import WarService from '@/services/War/WarService';
import ListService from '@/services/List/ListService';
import RedactionService from '@/services/Redaction/RedactionService';

import ListQuestions from '@/mixins/ListQuestions';

import { List, SaveList } from '@/globalInterfaces/List';
import { ConfigWar } from '@/globalInterfaces/War';
import {
  ReceivedRedaction as IReceivedRedaction,
  Redaction as IRedaction
} from '@/pages/Simulated/interface/ISimulated';

import { STATUS_RESPONSE_API } from '@/constant/StatusResponseAPI';
import { STEPS_WAR } from '@/constant/War';
import { MISSIONLIST_CONSTANT } from './constants';

@Component({
  components: {
    Tabs,
    Loading,
    ContainerInstructionProposal,
    ContainerSendRedaction,
    QuestionsListContainer,
    ButtonMovie,
    FeedbackUser,
    QuestionLoading,
    Buttons
  }
})
export default class Leveling extends Mixins(ListQuestions) {
  @Prop() start!: boolean;

  private isLoadingList = false;
  private tabActiveIsRedaction = false;
  private isOpenComponentsInputRedaction = false;
  private isLoadingButtonSend = false;
  private showedRedactionModal = false;

  private dateTimeStartList = '';
  private feedbackMessage = MISSIONLIST_CONSTANT.MSG_ERROR_LIST;

  private selectComponent = MISSIONLIST_CONSTANT.COMPONENT_QUESTION;
  private tabActive = '';

  private tabMenu: Array<TabsMenuInterface> = [];

  private redaction: IRedaction = {
    themeID: 0,
    content: ''
  }

  private list: List | null = null;

  private setModal = new ActiveModal();
  private setScroll = new ScrollControl();

  private WarService = new WarService();
  private ListService = new ListService();
  private RedactionService = new RedactionService();

  created() {
    this.resetStatusSentSimulatedInStore();

    this.getListQuestions();
  }

  mounted() {
    window.addEventListener('beforeunload', this.listenReloadPage);
  }

  beforeDestroy() {
    window.removeEventListener('beforeunload', this.listenReloadPage);
  }

  get isShowAllQuestion() {
    const { formatShowingQuestions } = this.$store.getters;

    return formatShowingQuestions === MISSIONLIST_CONSTANT.FORMAT_SHOWING_QUESTIONS;
  }

  get themeIdRedaction() {
    return this.list?.theme.ID;
  }

  get dateFormatted() {
    return `${format(new Date(), MISSIONLIST_CONSTANT.DATE_FORMAT)}Z`;
  }

  get configWar(): ConfigWar | null {
    return this.$store.getters['War/configWar'];
  }

  @Watch('tabActiveIsRedaction')
  changeActiveTabComponent() {
    if (this.tabActiveIsRedaction) {
      this.selectComponent = this.isOpenComponentsInputRedaction ? MISSIONLIST_CONSTANT.COMPONENT_SEND_REDACTION : MISSIONLIST_CONSTANT.COMPONENT_REDACTION_INSTRUCTION;

    } else this.selectComponent = MISSIONLIST_CONSTANT.COMPONENT_QUESTION;
  }

  @Watch('isShowAllQuestion')
  @Watch('tabActiveIsRedaction')
  setScrollPage() {
    if (this.isShowAllQuestion || this.tabActiveIsRedaction) this.setScroll.scrollTopPage();
  }

  @Watch('start')
  startTimer() {
    this.dateTimeStartList = this.dateFormatted;
  }

  async getListQuestions() {
    try {
      this.isLoadingList = true;

      const list = await this.WarService.getListLeveling();

      if (!list) return;

      this.list = list;

      const response = await this.ListService.getListQuestions({
        listQuestionsID: [list.ID],
        isOptions: true
      });

      if (response) {
        this.separateQuestionsByArea(this.groupQuestionsByAreas(response));

        this.setTabMenu();
      }
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: MISSIONLIST_CONSTANT.MSG_ERROR_LIST,
        status: 'error'
      });
    } finally {
      this.isLoadingList = false;
    }
  }

  async saveLeveling() {
    try {
      this.isLoadingButtonSend = true;

      if (!this.list) return;

      const answers = this.modalSaveAnswerQuestions();

      if (!answers) return;

      let redactionID: number | null = null;
      const startDate = this.dateTimeStartList;
      const endDate = `${format(new Date(), MISSIONLIST_CONSTANT.DATE_FORMAT)}Z`;
      const time = differenceInSeconds(new Date(endDate), new Date(this.dateTimeStartList));

      if (this.redaction.themeID && this.configWar?.isRedaction) {
        const type = this.redaction.content ? 'content' : 'URL';

        const response = await this.RedactionService.saveRedaction({
          themeID: this.redaction.themeID,
          [type]: this.redaction.content || this.redaction.URL,
          time: null
        });

        redactionID = response.data.id;
      }

      const saveList: SaveList = {
        listQuestionsID: this.list.ID,
        startDate,
        endDate,
        time,
        redactionID,
        answers
      };

      const response = await this.ListService.saveList(saveList);

      if (response.status === STATUS_RESPONSE_API.SUCCESS_CREATED) {
        this.$store.dispatch('Toast/setToast', {
          text: 'Lista salva com sucesso!',
          status: 'success'
        });

        this.$store.commit('War/setConfig', {
          ...this.configWar,
          step: {
            ...this.configWar?.step,
            ID: STEPS_WAR.RESULT_QUESTIONS
          }
        });
      }
    } catch (error) {
      this.$store.commit('setSimulatedSent', true);

      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao salvar a lista.',
        status: 'error'
      });

      console.error(error);
    } finally {
      this.isLoadingButtonSend = false;
    }
  }

  resetStatusSentSimulatedInStore() {
    this.$store.commit('setSimulatedSent', false);
  }

  listenReloadPage(e: BeforeUnloadEvent) {
    e.preventDefault();

    e.returnValue = '';

    return '';
  }

  setTabMenu() {
    let newFilteredTab: TabsMenuInterface[] = MISSIONLIST_CONSTANT.LIST_TAB
      .filter((tab: TabsMenuInterface) => (tab.id ? this.newQuestions[tab.id].length : ''));

    if (this.list?.theme?.ID && this.configWar?.isRedaction) {
      newFilteredTab = [
        ...newFilteredTab,
        {
          title: MISSIONLIST_CONSTANT.TAB_REDACTION.title,
          id: MISSIONLIST_CONSTANT.TAB_REDACTION.id,
          active: false
        }
      ];
    }

    newFilteredTab[0] = { ...newFilteredTab[0], active: true };

    this.tabMenu = newFilteredTab;

    this.setTabActive();
  }

  setTabActive() {
    this.tabActive = this.tabMenu[0].id || '';
  }

  clickButtonFinishModalQuestion() {
    if (this.list?.theme?.ID && this.configWar?.isRedaction) {
      this.showedRedactionModal = true;
    }

    this.saveLeveling();
  }

  clickLeftButtonModalRedaction() {
    if (this.list?.theme?.ID && this.configWar?.isRedaction) {
      this.showedRedactionModal = true;
    }
  }

  clickRightButtonModalRedaction() {
    const indexTabRedaction = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === MISSIONLIST_CONSTANT.TAB_REDACTION.id);

    this.setCurrentlyActiveTab(indexTabRedaction);

    this.handleActiveTabSwitching(indexTabRedaction, this.tabMenu);
  }

  verifyAnsweredQuestions() {
    const isAnsweredQuestions = this.answerQuestions.length === this.totalQuantityQuestions;

    return isAnsweredQuestions;
  }

  getInputRedaction(receivedRedaction: IReceivedRedaction) {
    if (!this.themeIdRedaction) return;

    this.redaction = {
      themeID: this.themeIdRedaction,
      [receivedRedaction.type]: UrlTreatmentSaveVersa(receivedRedaction.content)
    };
  }

  setCurrentlyActiveTab(indexTab: number) {
    this.tabMenu = this.tabMenu.map((tab: TabsMenuInterface, index: number) => {
      if (index === indexTab) return { ...tab, active: true };

      return { ...tab, active: false };
    });
  }

  setNextTab() {
    const indexNextTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive) + MISSIONLIST_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexNextTab);

    if (indexNextTab !== -1) this.handleActiveTabSwitching(indexNextTab, this.tabMenu);
  }

  setPreviousTab() {
    const indexPreviousTab = this.tabMenu.findIndex((tab: TabsMenuInterface) => tab.id === this.tabActive) - MISSIONLIST_CONSTANT.NEXT_PREVIOUS_TAB_NUMBER;

    this.setCurrentlyActiveTab(indexPreviousTab);

    if (indexPreviousTab !== -1) this.handleActiveTabSwitching(indexPreviousTab, this.tabMenu);
  }

  handleActiveTabSwitching(indexTabSelected: number, tabData: Array<TabsMenuInterface>) {
    this.tabMenu = tabData;

    this.tabActiveIsRedaction = tabData[indexTabSelected].id === MISSIONLIST_CONSTANT.TAB_REDACTION.id;

    this.tabActive = tabData[indexTabSelected].id || '';
  }
}
