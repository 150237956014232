import { render, staticRenderFns } from "./TrainingCenter.vue?vue&type=template&id=4eb926ee&scoped=true&"
import script from "./TrainingCenter.vue?vue&type=script&lang=ts&"
export * from "./TrainingCenter.vue?vue&type=script&lang=ts&"
import style0 from "./TrainingCenter.scss?vue&type=style&index=0&id=4eb926ee&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4eb926ee",
  null
  
)

export default component.exports