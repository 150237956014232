


























































































import { Component, Watch, Mixins } from 'vue-property-decorator';

import ExerciseContainer from '@/components/Exercise/ExerciseContainer/ExerciseContainer.vue';
import MapQuestions from '@/components/Exercise/MapQuestions/MapQuestions.vue';
import ResizeText from '@/components/Exercise/ResizeText/ResizeText.vue';
import Question from '@/components/Exercise/Question/Question.vue';
import RadioAlternative from '@/components/Exercise/RadioAlternative/RadioAlternative.vue';
import NavigationQuestion from '@/components/Exercise/NavigationQuestion/NavigationQuestion.vue';

import Scissors from '@/assets/icons/Scissors.vue';
import Brush from '@/assets/icons/Brush.vue';

import ToggleAllQuestions from './components/ToggleAllQuestions/ToggleAllQuestions.vue';

import ConfigMapQuestions from '@/pages/Simulated/mixins/ConfigMapQuestions';

const INDEX_FIRST_QUESTION_TAB = 0;

@Component({
  components: {
    ExerciseContainer,
    MapQuestions,
    ResizeText,
    Scissors,
    Brush,
    Question,
    RadioAlternative,
    NavigationQuestion,
    ToggleAllQuestions
  }
})
export default class QuestionsListContainer extends Mixins(ConfigMapQuestions) {
  private fontSizeQuestion = 16;
  private fontSizeOptions = 14;

  get isMaximumFontSizeQuestion() {
    return this.fontSizeQuestion === 18;
  }

  get isMinimumFontSizeQuestion() {
    return this.fontSizeQuestion === 10;
  }

  get isMaximumFontSizeOptions() {
    return this.fontSizeOptions === 18;
  }

  get isMinimumFontSizeOptions() {
    return this.fontSizeOptions === 10;
  }

  @Watch('isAllQuestions')
  setQuestionTab() {
    if (!this.isAllQuestions) {
      this.questionTab = this.questions[this.tabActive][INDEX_FIRST_QUESTION_TAB];

      this.currentNumbersQuestionTab[this.tabActive] = this.currentNumberQuestion() + 1;

      this.indexQuestion[this.tabActive] = INDEX_FIRST_QUESTION_TAB;
    } else this.indexQuestion[this.tabActive] = INDEX_FIRST_QUESTION_TAB;
  }

  increaseText() {
    this.fontSizeQuestion = this.isMinimumFontSizeQuestion
      ? this.fontSizeQuestion
      : this.fontSizeQuestion - 1;

    this.fontSizeOptions = this.isMinimumFontSizeOptions
      ? this.fontSizeOptions
      : this.fontSizeOptions - 1;
  }

  decreaseText() {
    this.fontSizeQuestion = this.isMaximumFontSizeQuestion
      ? this.fontSizeQuestion
      : this.fontSizeQuestion + 1;

    this.fontSizeOptions = this.isMaximumFontSizeOptions
      ? this.fontSizeOptions
      : this.fontSizeOptions + 1;
  }

  answerQuestion(
    indexAlternative: number,
    idQuestion: number,
    indexQuestion: number,
    idOption: number
  ) {
    if (this.isAllQuestions) this.indexQuestion[this.tabActive] = indexQuestion;

    this.$emit('answerQuestion', { question_id: idQuestion, option_id: idOption }, indexQuestion);
  }

  setAllQuestions(value: boolean) {
    this.$store.commit('saveFormatShowingQuestions', value ? 'all' : 'one');
  }

  // handleSetScissors() {
  //   this.$emit('scissors');
  // }

  // handleSetBrush() {
  //   this.$emit('brush');
  // }
}
