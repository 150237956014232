




















import {
  Component, Vue, Prop, Watch 
} from 'vue-property-decorator';

import MenuIcon from '@/assets/icons/war/Menu.vue';
import SquareIcon from '@/assets/icons/war/Square.vue';

@Component({
  components: {
    MenuIcon,
    SquareIcon
  }
})
export default class ToggleAllQuestions extends Vue {
  @Prop({ default: false }) isAllQuestions!: boolean;

  private localIsAllQuestions = false;

  @Watch('isAllQuestions', {
    immediate: true
  })
  setIsAllQuestions() {
    this.localIsAllQuestions = this.isAllQuestions;
  }

  toggleAllQuestions() {
    this.localIsAllQuestions = !this.localIsAllQuestions;

    this.$emit('onChange', this.localIsAllQuestions);
  }
}
