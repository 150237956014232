













import { Component, Vue, Prop } from 'vue-property-decorator';
import Close from '@/assets/icons/war/Close.vue';

@Component({
  components: {
    Close
  }
})
export default class Modal extends Vue {
  @Prop({ type: Boolean, default: false }) visible!: boolean;
  @Prop({ type: Boolean, default: true }) showClose!: boolean;

  handleClose() {
    this.$emit('close');
  }
}
