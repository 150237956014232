















import { Vue, Component, Prop } from 'vue-property-decorator';
import Character from '../../Character/Character.vue';

@Component({
  components: {
    Character
  }
})

export default class ModalCharacter extends Vue {
  @Prop({ default: false }) isVisible!: boolean;

  closeModal() {
    this.$emit('close');
  }
}
