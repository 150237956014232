








































































import { Component as BaseComponent } from 'vue';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Modal extends Vue {
  @Prop({ required: true }) stepKey!: string;
  @Prop({ required: true }) icon!: BaseComponent;

  get stepContent() {
    switch (this.stepKey) {
      case 'recruit':
        return {
          color: '#2F58AF',
          title: 'Centro de Recrutamento:',
          text: 'Aqui começa sua jornada! Escolha entre as missões recomendados ou crie uma personalizada para moldar suas tropas iniciais.'
        };
      case 'call':
        return {
          color: '#B32738',
          title: 'Centro de Convocação:',
          text: 'Tropas convocadas! Suas missões vêm para cá se acertar entre 0% e 59% das questões do assunto. Um começo humilde, mas um passo importante para crescer.'
        };
      case 'selection':
        return {
          color: '#CD841B',
          title: 'Centro de Seleção:',
          text: 'Prova de valor! Suas missões que acertaram entre 60% e 79% se alistam aqui, demonstrando força promissora no campo de batalha.'
        };
      case 'incorporation':
        return {
          color: '#267E2C',
          title: 'Centro de Incorporação:',
          text: 'Força máxima! Suas missões que alcançaram de 80% a 99% estão neste centro. Aqui você poderá visualizar todas as missões que já executou e ganhará uma bonificação aumentando 5% de podério militar da sua carta de batalha.'
        };
      case 'improvement':
        return {
          color: '#6E04C0',
          title: 'Centro de Aperfeiçoamento:',
          text: 'Erros fazem parte da evolução! Se errar 3 questões ou mais, sua missão virá para cá para reforçar o treinamento. As missões do Centro de Incorporação também passam por aqui depois de um tempo para aprimoramento contínuo.'
        };
      default:
        return null;
    }
  }
}
