

















import { Component, Vue } from 'vue-property-decorator';

import MissionListLoading from './MissionListLoading.vue';
import Background from '../../components/Background/Background.vue';
import MissionList from '../../components/Mission/MissionList.vue';

@Component({
  components: {
    Background,
    MissionListLoading,
    MissionList
  }
})
export default class MissionListPage extends Vue {
  private isLoading = false;
  private startMission = false;

  handleStartMission() {
    this.startMission = true;
  }
}
