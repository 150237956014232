



































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Sword from '@/assets/icons/war/Sword.vue';
import ArrowTarget from '@/assets/icons/war/ArrowTarget.vue';
import TankDetailed from '@/assets/icons/war/TankDetailed.vue';
import Aeroplane from '@/assets/icons/war/Aeroplane.vue';
import Cap from '@/assets/icons/war/Cap.vue';
import Button from '../Button/Button.vue';
import Play from '@/assets/icons/war/Play.vue';
import Close from '@/assets/icons/Close.vue';
import Ellipsis from '@/assets/icons/war/Ellipsis.vue';
import ModalCharacter from '../Modals/Character/ModalCharacter.vue';
import Delete from '@/assets/icons/war/Delete.vue';

@Component({
  components: {
    Sword,
    ArrowTarget,
    TankDetailed,
    Aeroplane,
    Cap,
    Button,
    Play,
    Ellipsis,
    ModalCharacter,
    Delete,
    Close
  }
})
export default class MissionCard extends Vue {
  @Prop({ required: true, type: String }) iconName!: string;
  @Prop({ default: '#9B78FE', type: String }) iconColor!: string;
  @Prop({ required: true, type: String }) label!: string;
  @Prop({ required: true, type: String }) title!: string;
  @Prop({ required: true, type: Number }) soldierCount!: number;
  @Prop({ required: true, type: String }) recurrence!:
    | 'high'
    | 'medium'
    | 'low';
  @Prop({ required: true, type: String }) counter!: number;
  @Prop({ required: false, type: Number }) listQuestionID!: number;
  @Prop({ required: false, type: Number }) status!: number;

  private STATUS_UNAVAILABLE = 5;
  private STATUS_AVAILABLE = 1;

  get iconComponent() {
    const iconMap: { [key: string]: any } = {
      sword: Sword,
      target: ArrowTarget,
      tank: TankDetailed,
      aeroplane: Aeroplane
    };

    return iconMap[this.iconName?.toLowerCase()] || null;
  }

  characterShow = false;

  modalCharacterShow() {
    this.characterShow = true;
    setTimeout(() => {
      this.characterShow = false;
    }, 8000);
  }

  handleInit() {
    if (this.status === this.STATUS_UNAVAILABLE || (this.status === this.STATUS_AVAILABLE && this.listQuestionID)) {
      return;
    }
    this.$emit('handleInit');
  }
}
