

























import { Component, Vue, Prop } from 'vue-property-decorator';

import Movie from '@/assets/icons/Movie.vue';
import Tips from '@/assets/icons/Tips.vue';
import Flashcards from '@/assets/icons/Flashcards.vue';
import Assess from '@/assets/icons/Assess.vue';
import Extras from '@/assets/icons/Extras.vue';

@Component({
  components: {
    Movie,
    Tips,
    Flashcards,
    Assess,
    Extras
  }
})
export default class ButtonIcon extends Vue {
  @Prop() name!: string;
  @Prop() icon!: string;
  @Prop({ default: 'top' }) orientation!: string;

  private isActive = false;

  handleClick() {
    this.$emit('click');

    this.isActive = !this.isActive;
  }

  handleNotActive() {
    this.isActive = false;
  }
}
