












import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Play extends Vue {
  @Prop({ default: '24' }) width!: string;
  @Prop({ default: '24' }) height!: string;
}
