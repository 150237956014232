import { Vue, Component } from 'vue-property-decorator';
import { groupBy } from 'lodash';

import { QuestionList, OptionQuestion, SaveAnswers } from '@/globalInterfaces/List';
import {
  AnsweredQuestion,
  NewOption,
  NewQuestion,
  NewStructureQuestions
} from '@/globalInterfaces/ListQuestions';

const QUESTION_ANSWERED = 'answered';
const QUESTION_SKIP = 'skip';
const AREA = {
  LANGUAGES: 'LINGUAGENS',
  HUMANITIES: 'HUMANAS',
  NATURE: 'NATUREZA'
};

@Component
export default class ListQuestions extends Vue {
  protected totalQuantityQuestions = 0;
  protected questions: QuestionList[] = [];
  protected newQuestions: Record<string, any> = {};

  protected answerQuestions: Array<AnsweredQuestion> = [];

  groupQuestionsByAreas(questions: QuestionList[]) {
    this.questions = questions;

    const newQuestion = groupBy(questions, 'area.name');

    return newQuestion;
  }

  separateQuestionsByArea(questions: Record<string, QuestionList[]>) {
    if (!questions) return;

    const newList = Object.keys(questions).map((key: string) => {
      this.totalQuantityQuestions += questions[key].length;

      return questions[key];
    });

    this.newQuestions = this.setStructureQuestionsList(newList);
  }

  setStructureQuestionsList(listQuestion: Array<QuestionList[]>) {
    const newList = listQuestion.reduce(
      (acc: NewStructureQuestions, discipline: QuestionList[]) => {
        discipline
          .map((question: QuestionList) => ({
            ...question,
            ID: question.ID,
            question: question.content,
            options: this.newListOption(question.options),
            response: null
          }))
          .forEach((question: any) => {
            if (AREA.LANGUAGES.includes(question.area.title)) acc.lc_lt.push(question);
            else if (AREA.HUMANITIES.includes(question.area.title)) acc.ch_ct.push(question);
            else if (AREA.NATURE.includes(question.area.title)) acc.cn_ct.push(question);
            else acc.mt.push(question);
          });

        return acc;
      },
      {
        lc_lt: [],
        ch_ct: [],
        cn_ct: [],
        mt: []
      }
    );

    return newList;
  }

  newListOption(options: Array<OptionQuestion>) {
    const newListOption = options.map((option: OptionQuestion) => ({
      ...option,
      title: option.content,
      id: option.ID,
      value: false
    }));

    return newListOption;
  }

  setAnswerQuestion(data: AnsweredQuestion, indexQuestionCurrent: number, tabActive: string) {
    const indexQuestionAnswered = this.answerQuestions.findIndex(
      (question: AnsweredQuestion) => question.question_id === data.question_id
    );

    if (indexQuestionAnswered !== -1) {
      this.answerQuestions[indexQuestionAnswered] = data;
    } else {
      this.answerQuestions.push(data);
    }

    this.setOptionSelected(data, indexQuestionCurrent, tabActive);
  }

  setOptionSelected(
    dataAnsweredQuestion: AnsweredQuestion,
    indexQuestionCurrent: number,
    tabActive: string
  ) {
    this.newQuestions[tabActive] = this.newQuestions[tabActive].map(
      (question: NewQuestion, indexQuestion: number) => {
        const newListOption = this.newListOptions(question, dataAnsweredQuestion);

        return this.setNewQuestionListStatus(
          question,
          dataAnsweredQuestion,
          newListOption,
          indexQuestionCurrent,
          indexQuestion
        );
      }
    );
  }

  newListOptions(question: NewQuestion, dataAnsweredQuestion: AnsweredQuestion) {
    const newListOption = question.options.map((option: NewOption) => {
      if (dataAnsweredQuestion.option_id === option.ID) {
        return {
          ...option,
          value: true
        };
      }

      if (dataAnsweredQuestion.option_id !== option.ID && option.value) {
        return {
          ...option,
          value: false
        };
      }

      return option;
    });

    return newListOption;
  }

  setNewQuestionListStatus(
    question: NewQuestion,
    answeredQuestion: AnsweredQuestion,
    newListOption: Array<NewOption>,
    indexQuestionCurrent: number,
    indexQuestion: number
  ) {
    if (question.ID === answeredQuestion.question_id) {
      return {
        ...question,
        response: QUESTION_ANSWERED,
        options: newListOption
      };
    }

    if (indexQuestion < indexQuestionCurrent && !question.response) {
      return {
        ...question,
        response: QUESTION_SKIP,
        options: newListOption
      };
    }

    return question;
  }

  modalSaveAnswerQuestions(): SaveAnswers[] | null {
    if (!this.answerQuestions.length) return null;

    const saveAnswers: SaveAnswers[] | null = [];

    // eslint-disable-next-line consistent-return
    this.answerQuestions.forEach((answer) => {
      const question = this.questions.find((questionItem) => questionItem.ID === answer.question_id);

      if (!question) return null;

      const option = question.options.find((optionItem) => optionItem.ID === answer.option_id);

      if (!option) return null;

      const answerObject = {
        listQuestionsQuestionsID: option.listQuestionsQuestionsID,
        listQuestionsQuestionsOptionsID: option.listQuestionQuestionsOptions,
        ...(option?.validated !== null && { validated: option.validated })
      };

      saveAnswers.push(answerObject);
    });

    return saveAnswers;
  }
}
