


































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Call extends Vue {
  @Prop({ default: '100' }) width!: string;
  @Prop({ default: '100' }) height!: string;
}
