





























































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import Menu from '../../components/Menu/Menu.vue';
import Background from '../../components/Background/Background.vue';
import TrainingSteps from '../../components/TrainingSteps/TrainingSteps.vue';
import MissionCard from '../../components/MissionCard/MissionCard.vue';
import SidebarCard from '../../components/SidebarCard/SidebarCard.vue';
import Info from '@/assets/icons/war/Info.vue';
import Button from '../../components/Button/Button.vue';
import FilterIcon from '@/assets/icons/war/Filter.vue';
import Personalize from '@/assets/icons/war/Personalize.vue';

import WarService from '@/services/War/WarService';

interface Mission {
  iconName: 'sword' | 'target' | 'tank' | 'aeroplane';
  label: string;
  title: string;
  soldierCount: number;
  recurrence: 'high' | 'medium' | 'low';
  counter: number;
  id: number;
  listQuestionID: number | null;
  status: number;
}

interface Step {
  id: number;
  title: string;
  hasSidebar: boolean;
  missions: Mission[];
}

@Component({
  components: {
    Menu,
    Background,
    TrainingSteps,
    MissionCard,
    SidebarCard,
    Info,
    Button,
    FilterIcon,
    Personalize
  }
})
export default class TrainingCenter extends Vue {
  private activeStep = 0;
  private listMenu = [];
  private showFilter = false;
  private isLoading = false;
  private warService = new WarService();

  private steps: Step[] = [
    {
      // Recrutar
      id: 5,
      title: 'Missões recrutadas',
      hasSidebar: true,
      missions: []
    },
    {
      // Convocar
      id: 6,
      title: 'Missões convocadas',
      hasSidebar: false,
      missions: []
    },
    {
      // Seleção
      id: 7,
      title: 'Missões selecionadas',
      hasSidebar: false,
      missions: []
    },
    {
      // Incorporação
      id: 8,
      title: 'Missões incorporadas',
      hasSidebar: false,
      missions: []
    },
    {
      // Aprimoramento
      id: 9,
      title: 'Missões aprimoradas',
      hasSidebar: false,
      missions: []
    }
  ];

  private recommendationMissions: Mission[] = [];

  mounted() {
    this.load();
  }

  get missionActiveCount() {
    return this.steps[this.activeStep].missions.filter((mission) => mission.status === 1).length;
  }

  @Watch('activeStep')
  async getSelectedTopics() {
    try {
      this.isLoading = true;

      const icons = ['sword', 'target', 'tank', 'aeroplane'];
      let idxIcon = 0;
      const response = await this.warService.getSelectedTopics(this.steps[this.activeStep].id);
      const selectedItems: Mission[] = response.map((missionResponse: any) => {
        const iconName = icons[idxIcon];
        if (idxIcon === 3) {
          idxIcon = 0;
        } else {
          idxIcon += 1;
        }

        return {
          id: missionResponse.ID,
          iconName,
          label: missionResponse.disciplineTitle,
          title: missionResponse.topicTitle,
          soldierCount: missionResponse.soldiers,
          recurrence: missionResponse.recurrence,
          counter: 10, /* total questoes, padrão 10 */
          status: missionResponse.status,
          listQuestionID: missionResponse.listQuestionID
        } as Mission;
      });
      const selectedItemsOrderedByStatus = selectedItems.sort((a, b) => a.status - b.status);
      this.steps[this.activeStep].missions = selectedItemsOrderedByStatus;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar missões selecionadas.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  async load() {
    this.getRecommendations();
    this.getSelectedTopics();
  }

  async getRecommendations() {
    try {
      this.isLoading = true;
      this.recommendationMissions = [];
      const icons = ['sword', 'target', 'tank', 'aeroplane'];
      let idxIcon = 0;
      const response = await this.warService.getRecommendations();
      const recommendationsItems: Mission[] = response.map((missionResponse: any) => {
        const iconName = icons[idxIcon];
        if (idxIcon === 3) {
          idxIcon = 0;
        } else {
          idxIcon += 1;
        }

        return {
          id: missionResponse.ID,
          iconName,
          label: missionResponse.disciplineTitle,
          title: missionResponse.topicTitle,
          soldierCount: missionResponse.soldiers,
          recurrence: missionResponse.recurrence,
          counter: 10 /* total questoes, padrão 10 */
        } as Mission;
      });
      this.recommendationMissions = recommendationsItems;
    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao carregar missões sugeridas.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleSelectTopic(mission: Mission) {
    try {
      this.isLoading = true;

      await this.warService.SelectedTopic(mission.id);

      this.$store.dispatch('Toast/setToast', {
        text: 'Missão selecionada com sucesso.',
        status: 'success'
      });

      this.load();
    } catch (error) {
      if (error?.response?.data?.message) {
        this.$store.dispatch('Toast/setToast', {
          text: error?.response?.data?.message,
          status: 'error'
        });
      } else {
        this.$store.dispatch('Toast/setToast', {
          text: 'Erro ao selecionar missão.',
          status: 'error'
        });
      }
    } finally {
      this.isLoading = false;
    }
  }

  async handleTopicInit(mission: Mission) {
    console.log('🚀 ~ TrainingCenter ~ handleTopicInit ~ mission:', mission);
    try {
      this.isLoading = true;

      await this.warService.GenerateList(mission.id);
      // lista topico com status 1 quer dizer ok
      // lista topico com status 5 esta em preparação, adicionar aviso na tela
      this.$store.dispatch('Toast/setToast', {
        text: 'Missão iniciada com sucesso.',
        status: 'success'
      });

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 2000);
      });

      this.getSelectedTopics();

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao selecionar missão.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  async handleDelete(mission: Mission) {
    try {
      this.isLoading = true;

      await this.warService.removeSelectedTopic(mission.id);

      this.$store.dispatch('Toast/setToast', {
        text: 'Missão removida com sucess.',
        status: 'success'
      });

      this.load();

    } catch (error) {
      this.$store.dispatch('Toast/setToast', {
        text: 'Erro ao selecionar missão.',
        status: 'error'
      });
    } finally {
      this.isLoading = false;
    }
  }

  handleStepChange(index: number): void {
    this.activeStep = index;
  }

  handleFilterClick(): void {
    this.showFilter = !this.showFilter;
  }

  handlePersonalize(): void {
    this.showFilter = false;
    console.log('clicked');
  }
}
